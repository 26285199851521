import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import MapPng from '../assets/PNG/iPhone_small_2025@2x.png';
import MapZoomPng from '../assets/PNG/iPhone_zoom_2025@2x.png';
import closePng from '../assets/PNG/icn_close@2x.png';
import data from '../data.json';
import images from '../helper/Images';
// import Carousel from 'react-material-ui-carousel';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

// Carousel Slides
/*
function Slide(props)
{
  return (
    <div className='carousel-item' key={`table-${props.table.title}`}>
      <div className={`title ${props.table.title === "Meeting tables" ? 'title--extra-padding' : ''}`} >{props.table.title}</div>
      <div className="desks">
        <hr></hr>
        <div className="desks-content">
        {props.table.desks && props.table.desks.map((desk, index) => (
          <div className="desk" key={`desk-${index}`}>
            <div className='desk-no'>{desk.desk}</div>
            <div className='desk-seats'>
              {desk.seats && desk.seats.map((seat, index) => (
              <div className='seat' key={`seat-${index}`}>
                <span className='seat-name'>{seat.name}</span>
                <span className='seat-contact'>{seat.contact}</span>
                <span className='seat-email'><a href={`mailto:${seat.email}`}>→ Send an email</a></span>
              </div>
              ))}
            </div>
          </div>
        ))}
        </div>
      </div>
    </div>
  )
}*/
// Carousel Slides
function Slide({ table }) {
  return (
    <div className='carousel-item'>
      <div className={`title ${table.title === "Meeting tables" ? 'title--extra-padding' : ''}`}>{table.title}</div>
      <div className="desks">
        <hr></hr>
        <div className="desks-content">
          {table.desks?.map((desk, index) => (
            <div className="desk" key={`desk-${index}`}>
              <div className='desk-no'>{desk.desk}</div>
              <div className='desk-seats'>
                {desk.seats?.map((seat, index) => (
                  <div className='seat' key={`seat-${index}`}>
                    <span className='seat-name'>{seat.name}</span>
                    <span className='seat-contact'>{seat.contact}</span>
                    <span className='seat-email'><a href={`mailto:${seat.email}`}>→ Send an email</a></span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const Map = (props) => {
  const [popupHeight, setPopupHeight] = useState('calc(100% - 385px)');
  const [popupTop, setPopupTop] = useState('110px');
  const [mapContainerHeight, setMapContainerHeight] = useState('auto');
  const mapContainer = useRef(null);
  const mapSmallContainer = useRef(null);
  const mapLargeContainer = useRef(null);
  const mapSmallImage = useRef(null);
  const mapLargeImage = useRef(null);
  const mapHelper = useRef(null);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    fade: true,
    arrows: true,
    beforeChange: (_, next) => {
      props.setCarouselIndex(next);
      updateActiveTable(next);
    },
  };

  const handleZoom = (e) => {
    e.preventDefault();

    const currentDate = new Date().toISOString().split('T')[0];
    const zoomStatus = props.mapZoomedIn ? 'zoom out' : 'zoom in';

    const eventData = {
      date: currentDate,
      action: zoomStatus
    };

    logEventToCSV(eventData);

    // only zoom in mobile view
    if(props.width < 768) {
      // toggle zoom
      props.setMapZoomedIn(!props.mapZoomedIn);


      if(props.popup == 'popup active') {
        if(props.mapZoomedIn) {
          props.setIntroZoom('fadeOut');
        } else {
          props.setIntroZoom('fadeOut zoomedOut');
        }
      } else {
        if(props.mapZoomedIn == true) {
          props.setIntroZoom('');
        } else {
          props.setIntroZoom('zoomedOut');
        }
      }
    }
  }

  const logEventToCSV = async (eventData) => {
    const csvData = `${eventData.date},${eventData.action},${eventData.table}\n`;

    try {
        const response = await fetch('./events.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain', // Or 'application/json' if sending JSON data
            },
            body: csvData,
        });

        if (response.ok) {
        } else {
        }
    } catch (error) {
    }
  };

  // show popup and fadeout intro
  const handlePopup = (table, index) => (e) => {
    e.preventDefault();

    const currentDate = new Date().toISOString().split('T')[0];

    const eventData = {
      date: currentDate,
      action: 'overview',
      table: table.table
    };

    logEventToCSV(eventData);
    
    if(props.introZoom == 'zoomedOut' || props.introZoom == 'fadeOut zoomedOut') {
      props.setIntroZoom('fadeOut zoomedOut');
    } else {
      if(props.width >= 768) {
        props.setIntroZoom('fadeOut zoomedOut');
      } else {
        props.setIntroZoom('fadeOut');
      }
    }

    if(props.width >= 768) {
      let windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      let mapHeight = mapContainer.current.clientHeight;
      let headerHeight = props.appHeader.current.clientHeight;
      let popupHeightOffset = mapHeight + headerHeight + 50;

      if(props.width >= 768) {
        popupHeightOffset = popupHeightOffset + 180;
        let paddingBottom = windowHeight - 20 - mapHeight - 250 - headerHeight;
        props.setIntroContentPaddingBottom(paddingBottom);
      }
    }

    props.setPopup('popup active');
    props.setCarouselIndex(index);
    sliderRef.current.slickGoTo(index);
    updateActiveTable(index);
  }

  const updateActiveTable = (index) => {
    document.querySelectorAll('.map-small .table, .map-large .table').forEach((el) => {
      el.classList.remove('active');
    });
    const smallTable = document.querySelector(`.map-small .table:nth-child(${index + 1})`);
    const largeTable = document.querySelector(`.map-large .table:nth-child(${index + 1})`);
    if (smallTable) smallTable.classList.add('active');
    if (largeTable) largeTable.classList.add('active');
  };

  // close popup, fadein intro and unset active table
  const closePopup = (e) => {
    e.preventDefault();

    const currentDate = new Date().toISOString().split('T')[0];

    const eventData = {
      date: currentDate,
      action: 'close'
    };

    logEventToCSV(eventData);

    if(props.introZoom == 'fadeOut zoomedOut') {
      props.setIntroZoom('zoomedOut');
    } else {
      props.setIntroZoom('');
    }
    if(props.width >= 768) {
      props.setIntroZoom('');
      props.setIntroContentPaddingBottom(0);
    }

    unsetActiveTable();

    props.setPopup('popup');
  }

  const mapSmallClass = props.mapZoomedIn ? 'map-small zoomedIn' : 'map-small';
  const mapLargeClass = props.mapZoomedIn ? 'map-large zoomedIn' : 'map-large';
  const mapClass = props.mapZoomedIn ? 'map zoomedIn' : 'map';

  // calculate popup height
  useEffect(() => {
    let currentMapHeight = 'auto';

    try {
      let mapSmallImageHeight = mapHelper.current.clientHeight;
      let mapHelperHeight = mapHelper.current.clientHeight;
      let mapLargeImageHeight = mapLargeImage.current.clientHeight;

  
      if(!props.mapZoomedIn) {
        if(mapSmallImageHeight == undefined || mapSmallImageHeight == 0) {
          setMapContainerHeight(mapHelperHeight+'px');
          currentMapHeight = mapHelperHeight;
        } else {
          setMapContainerHeight(mapSmallImageHeight+'px');
          currentMapHeight = mapSmallImageHeight;
        }
      } else {
        setMapContainerHeight(mapLargeImageHeight+'px');
        currentMapHeight = mapLargeImageHeight;
      }
  
    } catch (exceptionVar) {
      console.log();
    } finally {
      let mapHeight = currentMapHeight + 10;
      let headerHeight = props.appHeader.current.clientHeight;
      let popupHeightOffset = mapHeight + headerHeight + 50;
  
      if(props.width >= 768) {
        popupHeightOffset = popupHeightOffset + 180;
      }
  
      if(props.mapZoomedIn) {
        popupHeightOffset = popupHeightOffset - 90;
      }
  
      setPopupHeight('calc(100% - '+popupHeightOffset+'px)');
    }





  }, [props.popup, props.mapZoomedIn]);

  // calculate map container height
  /*useLayoutEffect(() => {
  }, [props.mapZoomedIn]);*/

  // update active table
  useEffect(() => {
    unsetActiveTable();

    if(props.popup == 'popup active') {
      mapSmallContainer.current.children[props.carouselIndex].className = 'table active';
      mapLargeContainer.current.children[props.carouselIndex].className = 'table active';
      let mapLargeImageWidth = mapLargeImage.current.clientWidth;

      console.log(props.carouselIndex);

      if((props.carouselIndex > 1 && props.carouselIndex < 5) || (props.carouselIndex > 12 && props.carouselIndex < 16) || props.carouselIndex == 22) {
        console.log('scroll center');
        const container = document.querySelector('.map-large');
        const mapLargeTables = document.querySelector('.map-large .tables');
        const mapLarge = document.querySelector('.map-large');
        
        const mapLargeWidth = mapLarge.offsetWidth;
        const mapLargeTablesWidth = mapLargeTables.offsetWidth;

        const targetScrollLeft = (mapLargeTablesWidth - mapLargeWidth) / 2;

        let scrollAmount = 0;
        const step = container.scrollLeft < targetScrollLeft ? 10 : -10;

        var slideTimer = setInterval(function() {
          container.scrollLeft += step;
          scrollAmount += Math.abs(step);

          const remainingDistance = Math.abs(targetScrollLeft - container.scrollLeft);

          if (remainingDistance <= Math.abs(step)) {
            window.clearInterval(slideTimer);
            container.scrollLeft = targetScrollLeft;
          }
        }, 3); 
      } else if(props.carouselIndex > 4 && props.carouselIndex < 13) {
        console.log('scroll right');
        let scrollAmount = 0;
        var slideTimer = setInterval(function(){
          document.querySelector('.map-large').scrollLeft += 10;
          scrollAmount += 10;
          if(scrollAmount >= mapLargeImageWidth){
              window.clearInterval(slideTimer);
          }
        }, 3);
      } else {
        console.log('scroll left');
        let scrollAmount = 0;
        var slideTimer = setInterval(function(){
          document.querySelector('.map-large').scrollLeft -= 10;
          scrollAmount += 10;
          if(scrollAmount >= mapLargeImageWidth){
              window.clearInterval(slideTimer);
          }
        }, 3);
      }
      
    }

  }, [props.carouselIndex]);

  // handle header visibility
  useEffect(() => {
    if(props.width >= 768) {
      props.setHeaderClass('container');
    } else {
      if(!props.mapZoomedIn) {
        props.setHeaderClass('container');
      }
      if(props.popup == 'popup') {
        props.setHeaderClass('container');
      }
      if(props.popup == 'popup active' && props.mapZoomedIn) {
        props.setHeaderClass('container fadeOut');
      }
    }

  }, [props.popup, props.mapZoomedIn]);

  // handle popup position
  useEffect(() => {
    if(props.width >= 768) {
      setPopupTop('310px');
    } else {
      if(props.headerClass == 'container fadeOut') {
        setPopupTop('20px');
      } else {
        setPopupTop('110px');
      }
    }

  }, [props.headerClass]);

  // unset table active states
  function unsetActiveTable() {
    let tableCount = mapSmallContainer.current.children.length;

    for (let i = 0; i < tableCount; i++) {
      mapSmallContainer.current.children[i].className = 'table';
      mapLargeContainer.current.children[i].className = 'table';
    }
  }

  // update carouselIndex on swipe
  function updateCarouselIndex(direction) {
    let tableCount = mapSmallContainer.current.children.length;
    let currentIndex = props.carouselIndex;
    let newIndex = 0;

    if(direction == 'next') {
      if(currentIndex == (tableCount - 1)) {
        newIndex = 0;
      } else {
        newIndex = currentIndex + 1;
      }
    }

    if(direction == 'prev') {
      if(currentIndex == 0) {
        newIndex = tableCount - 1;
      } else {
        newIndex = currentIndex - 1;
      }
    }

    const currentDate = new Date().toISOString().split('T')[0];
    const table = ((newIndex + 1) * 2) - 1;

    const eventData = {
      date: currentDate,
      action: 'arrows',
      table: table
    };

    logEventToCSV(eventData);

    setTimeout(() => {
      props.setCarouselIndex(newIndex);
    }, 550); 
  }

  const popup = () => (
    <div className={props.popup} style={{height: `${popupHeight}`, top: `${popupTop}`}}>
       <Slider ref={sliderRef} {...settings} initialSlide={props.carouselIndex}>
          {data.tables.map((table, index) => (
            <Slide key={index} table={table} />
          ))}
        </Slider>
      {/*<Carousel 
        className='popup-carousel' 
        swipe={true}
        animation="fade"
        fullHeightHover="true" 
        height="100%" 
        cycleNavigation="false" 
        navButtonsAlwaysVisible="true" 
        index={props.carouselIndex} 
        autoPlay={false}
        duration={500}
        indicatorIconButtonProps={{
            style: {
                display: 'none'
            }
        }}
        indicatorContainerProps={{
            style: {
                display: 'none'
            }
        }}
        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
          style: {
            padding: 0,
            borderRadius: 0,
            fontSize: '40px',
            width: '50px',
            height: '50px'
          }
        }} 
        next={ () => { updateCarouselIndex('next') } }
        prev={ () => { updateCarouselIndex('prev') } }
      >
        {
          data.tables.map( (table, index) => <Slide key={index} table={table} /> )
        }
      </Carousel>*/}
      <div className='popup-close' onClick={closePopup}><img src={closePng} /></div>
    </div>
  );

  return (
    <div className={mapClass} ref={mapContainer} style={{height: `${mapContainerHeight}`}}>
      <div className={mapSmallClass} key={0}>
        <img src={MapPng} alt="Logo" ref={mapSmallImage} />
        <div className="tables" ref={mapSmallContainer}>
          {data.tables && data.tables.map((table, index) => (
            <div key={index} className='table' style={{left: `${table.xSmall}%`, top: `${table.ySmall}%`}} onClick={handlePopup(table, index)}>
              <img src={images[table.imageSmall]} className={`normal ${table.type}`} key={0} />
              <img src={images[table.hoverSmall]} className={`hover ${table.type}`} key={1} />
            </div>
          ))}
        </div>
        <div className='zoom' onClick={handleZoom}></div>
      </div>
      <div className={mapLargeClass} key={1}>
        <img src={MapZoomPng} alt="Logo" ref={mapLargeImage} />
        <div className="tables" ref={mapLargeContainer}>
          {data.tables && data.tables.map((table, index) => (
            <div key={index} className='table' style={{left: `${table.x}%`, top: `${table.y}%`}} onClick={handlePopup(table, index)}>
              <img src={images[table.image]} className={`normal ${table.type}`} key={0} />
              <img src={images[table.hover]} className={`hover ${table.type}`} key={1} />
            </div>
          ))}
        </div>
        <div className='zoom' onClick={handleZoom}></div>
      </div>

      {popup()}

      <div className="map-helper" ref={mapHelper}></div>
    </div>
  )
}

export default Map