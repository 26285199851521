import React from 'react';

const Intro = (props) => {
  const introClass = 'intro ' + props.introZoom;

  return (
    <div className={introClass}>
      <div className='container'>
        <div className='intro-content' style={{paddingBottom: `${props.introContentPaddingBottom}px`}}>
          <div className='intro-logo'>
            <svg id="Ebene_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164.45 37.21">
              <g id="Ebene_9">
                  <g id="NL_Branding_Netherlands_01_RGB_FC">
                    <path id="Pfad_11" className="cls-1 svg-logo-1" d="m39.61,28.29v-.68c3.19-.87,5.87-3.21,5.87-9.29v-10.15c-.07-2.56.29-5.12,1.06-7.56-1.25-.43-2.57-.63-3.89-.6-3.75,0-6.6,1.91-6.66,6.49v30.51h27.04v-8.71h-23.43Z"/>
                    <path id="Pfad_12" className="cls-1 svg-logo-1" d="m27.79,0c-1.29-.03-2.58.17-3.79.6.7,2.25,1.02,4.6.95,6.96v10.02c-.13,2.7.63,5.37,2.16,7.6l-.74.49L10.33.55H0v36.45h9.35v-15.32c.08-2.5-.29-4.98-1.09-7.35.35-.21.65-.39.65-.39.96,1.78,1.72,3.14,2.64,4.52l12.07,18.53h10.77V6.64C34.39,1.99,31.58,0,27.79,0Z"/>
                    <path id="Pfad_13" className="cls-1 svg-logo-2" d="m71.76,25.93h2.34l4.8,8.52v-8.52h1.87v11.08h-2.34l-4.8-8.52v8.52h-1.87v-11.08Z"/>
                    <path id="Pfad_14" className="cls-1 svg-logo-2" d="m90.7,34.13c-.34,2.42-2.4,3.08-3.93,3.08-2.08,0-4.07-1.17-4.07-4.57s2.1-4.54,4.01-4.54,4.03,1.01,4.03,4.53v.3h-6.15c.04,1.85.79,2.8,2.24,2.8.99.1,1.89-.6,2.02-1.59h1.84Zm-1.87-2.35c-.12-1.47-.88-2.24-2.11-2.24s-1.99.77-2.12,2.24h4.23Z"/>
                    <path id="Pfad_15" className="cls-1 svg-logo-2" d="m92.82,29.74h-1.33v-1.45h.77c.67,0,.91-.28.91-.93v-1.77h1.55v2.7h2.58v1.45h-2.58v4.47c0,.93.26,1.43,1.25,1.43.41,0,.82-.07,1.21-.22v1.51c-.51.2-1.06.3-1.61.28-2.08,0-2.76-1.15-2.76-2.78v-4.69Z"/>
                    <path id="Pfad_16" className="cls-1 svg-logo-2" d="m98.85,23.86h1.9v5.36c.63-.8,1.62-1.22,2.63-1.13,1.43,0,2.96.6,2.96,3.54v5.38h-1.9v-5c0-1.47-.34-2.26-1.73-2.26-.73-.03-1.44.25-1.94.77v6.49h-1.9v-13.15Z"/>
                    <path id="Pfad_17" className="cls-1 svg-logo-2" d="m116.12,34.13c-.34,2.42-2.4,3.08-3.93,3.08-2.08,0-4.07-1.17-4.07-4.57s2.1-4.54,4.01-4.54,4.03,1.01,4.03,4.53v.3h-6.16c.04,1.85.79,2.8,2.24,2.8.99.1,1.89-.6,2.02-1.59h1.85Zm-1.87-2.35c-.12-1.47-.88-2.24-2.11-2.24s-1.99.77-2.12,2.24h4.23Z"/>
                    <path id="Pfad_18" className="cls-1 svg-logo-2" d="m118.03,28.29h1.63v1.15c.43-.89,1.36-1.42,2.34-1.35.35-.02.71.04,1.03.18v1.67c-.4-.16-.84-.24-1.27-.24-.71-.06-1.4.25-1.83.81v6.49h-1.91v-8.71Z"/>
                    <path id="Pfad_19" className="cls-1 svg-logo-2" d="m124.32,23.86h1.9v13.14h-1.9v-13.14Z"/>
                    <path id="Pfad_20" className="cls-1 svg-logo-2" d="m133.83,35.85c-.75.89-1.87,1.39-3.04,1.35-1.75,0-2.78-.97-2.78-2.46,0-2.1,2.06-2.54,3.47-2.66l2.02-.18v-.24c0-1.35-.22-2.1-1.63-2.1-1.27,0-1.71.62-1.71,1.71h-1.83c.04-2.62,2.04-3.18,3.73-3.18,2.52,0,3.31,1.25,3.31,3.38v3.73c0,.34.14.51.5.51.25,0,.49-.07.72-.18v1.35c-.42.21-.89.32-1.37.32-1.17,0-1.41-.65-1.41-1.23v-.12Zm-.32-2.82l-1.65.18c-1.23.14-1.97.56-1.97,1.45,0,.72.48,1.15,1.45,1.15.8,0,1.57-.3,2.16-.83v-1.94h0Z"/>
                    <path id="Pfad_21" className="cls-1 svg-logo-2" d="m137.94,28.29h1.63v1.13c.66-.92,1.75-1.43,2.88-1.33,1.45,0,2.98.6,2.98,3.54v5.38h-1.9v-5c0-1.47-.34-2.26-1.73-2.26-.73-.03-1.44.25-1.94.77v6.49h-1.91v-8.71Z"/>
                    <path id="Pfad_22" className="cls-1 svg-logo-2" d="m153.54,35.93c-.6.85-1.6,1.33-2.64,1.27-1.87,0-3.69-1.23-3.69-4.53s1.81-4.58,3.69-4.58c.91-.04,1.79.35,2.38,1.05v-5.28h1.9v13.14h-1.65v-1.07h0Zm-.26-5.5c-.49-.49-1.17-.76-1.87-.74-1.51,0-2.28,1.05-2.28,2.94s.77,2.96,2.28,2.96c.7.02,1.37-.24,1.87-.74v-4.43Z"/>
                    <path id="Pfad_23" className="cls-1 svg-logo-2" d="m158.84,34.19c.09,1.01.98,1.75,1.99,1.66.02,0,.05,0,.07,0,.97,0,1.67-.42,1.67-1.19,0-.95-1.03-1.07-2.62-1.53-1.25-.36-2.62-.85-2.62-2.52,0-1.83,1.69-2.5,3.35-2.5,1.92,0,3.36.89,3.47,2.74h-1.87c-.05-.82-.75-1.45-1.57-1.4-.03,0-.07,0-.1,0-.79,0-1.43.36-1.43,1.03,0,.91,1.13,1.01,2.68,1.45,1.27.36,2.58.91,2.58,2.6,0,2-1.83,2.68-3.59,2.68-2.15,0-3.73-.99-3.89-3.02h1.87Z"/>
                  </g>
              </g>
            </svg>
          </div>
          <h2>
            Hartelijk welkom
            <span><br />ITB 2025</span>
          </h2>
        </div>
      </div>
    </div>
  )
}

export default Intro