import './App.scss';
import Header from "./components/Header";
import Intro from "./components/Intro";
import Map from "./components/Map";
import Search from "./components/Search";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import FallbackImage from "./assets/PNG/fallback_desktop_2025@2x.png";
import LandscapeMobileImage from "./assets/PNG/icn_turn_phone@2x.png";
import LandscapeTabletImage from "./assets/PNG/icn_turn_tablet@2x.png";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  const [mapZoomedIn, setMapZoomedIn] = useState(false);
  const [introZoom, setIntroZoom] = useState('');
  const [popup, setPopup] = useState('popup');
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [introContentPaddingBottom, setIntroContentPaddingBottom] = useState(0);
  const [appHeight, setAppHeight] = useState('100%');
  const [mainHeight, setMainHeight] = useState('calc(100% - 100px)');
  const [width, setWidth] = useState(window.innerWidth);
  const [headerClass, setHeaderClass] = useState('container');
  const appHeader = useRef(null);
  const [windowWidth, windowHeight] = useWindowSize();
  

  // set global props
  let props = {
    mapZoomedIn: mapZoomedIn,
    setMapZoomedIn: setMapZoomedIn,
    introZoom: introZoom,
    setIntroZoom: setIntroZoom,
    popup: popup,
    setPopup: setPopup,
    carouselIndex: carouselIndex,
    setCarouselIndex: setCarouselIndex,
    width: width,
    setWidth: setWidth,
    headerClass: headerClass,
    setHeaderClass: setHeaderClass,
    appHeader: appHeader,
    introContentPaddingBottom: introContentPaddingBottom,
    setIntroContentPaddingBottom: setIntroContentPaddingBottom,
  };

  // calculate and set element heights to prevent change of height due to mobile tools (keyboard, password input, browser tools, etc.)
  useLayoutEffect(() => {
    let windowHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    let newHeight = windowHeight - 20;

    let newMainHeight = newHeight - 80;
    if(width >= 768) {
      newMainHeight = newHeight - 110;
    }
    setAppHeight(newHeight+'px');
    setMainHeight(newMainHeight+'px');
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if(windowWidth != width) {
        window.location.reload(false);
      }
    }, 350)

    return () => clearTimeout(delayDebounceFn);
  }, [windowWidth]);



  useEffect(() => {
    // Function to log visit
    const logVisit = async (date) => {
      try {
        const response = await fetch('./visitor.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ date: date, count: 1 }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
      }
    };

    const currentDate = new Date().toISOString().split('T')[0];
    const lastVisitDate = localStorage.getItem('lastVisitDate');

    if (lastVisitDate !== currentDate) {
      localStorage.setItem('lastVisitDate', currentDate);
      logVisit(currentDate);
    }
  }, []);


  return (
    <div className="App" style={{height: `${appHeight}`}}>
      <header className="App-header" ref={appHeader}>
        <Header {...props}/>
      </header>
      <main style={{height: `${mainHeight}`}}>
        <div className='content'>
          <Intro {...props}></Intro>
          <Map {...props}></Map>
        </div>
        <Search {...props}></Search>
      </main>

      <div className='fallback'><img src={FallbackImage} /></div>
      <div className='landscape-mobile'><img src={LandscapeMobileImage} /></div>
      <div className='landscape-tablet'><img src={LandscapeTabletImage} /></div>
    </div>
  );
}

export default App;